body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


footer {
  position: fixed;
  bottom: 0;
}

.title {
  text-align: center;
}

table {
  border: 1px solid;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  width: 50%;
}

th {
  padding: 10px;
  font-size: 30px;
}

td {
  border: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.table-lang {
  font-size: 20px;
  
}